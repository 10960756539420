import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  outSidePosition: {
    position: "relative",
  },
  inSidePosition: {
    position: "absolute",
    top: "-2.48px",
    right: "0px",
  },
}));

const getHelperText = (touched, errors) => {
  if (touched && errors) {
    return errors;
  }
};

export function AutocompleteComboBox({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  id,
  label,
  type,
  withFeedbackLabel = true,
  customFeedbackLabel,
  defaultValue,
  setFieldValue,
  options,
  ...props
}) {
  const classes = useStyles();
  return (
    <Autocomplete
      id={id}
      options={options}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        setFieldValue(field.name, newValue ? newValue.id : undefined);
      }}
      renderInput={(props) => 
      <TextField       
        variant="outlined"
        margin="normal"
        fullWidth
        label={label}
        type={type}
        error={touched[field.name] && errors[field.name]}
        helperText={getHelperText(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      }
    />
  );
}
