import { createSlice } from "@reduxjs/toolkit";

const initialWithdrawalState = {
  actionsLoading: false,
  withdrawalLogs: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

function getWednesdayOfWeek(date) {
  let day = date.getDay();
  return new Date(date.setDate(date.getDate() + (3 - day)));
}

export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState: initialWithdrawalState,
  reducers: {
    setWithdrawalLogs: (state, action) => {
      state.actionsLoading = false;
      state.withdrawalLogs = action.payload.withdrawalLogs;
    },
    pushWalletLogs: (state, action) => {
      state.actionsLoading = false;
      state.withdrawalLogs.push(action.payload.withdrawalLog);
    },
    clearWalletLogs: (state, action) => {
      state.actionsLoading = false;
      state.withdrawalLogs = [];
    },
    cancelWithdrawal: (state, action) => {
      state.withdrawalLogs = state.withdrawalLogs.map((withdrawal) => {
        if (withdrawal.id === action.payload.id) {
          withdrawal.status = 2;
          return withdrawal;
        }
        return withdrawal;
      });
    },
    BilledWithdrawal: (state, action) => {
      state.withdrawalLogs = state.withdrawalLogs.map((withdrawal) => {
        console.log(withdrawal.status);
        if (withdrawal.status === 0) {
          let now = new Date();
          let endTime = getWednesdayOfWeek(now);
          endTime.setHours(0);
          endTime.setMinutes(0);
          endTime.setSeconds(0);

          if (
            (new Date(withdrawal.time) < endTime && endTime <= new Date()) ||
            new Date(withdrawal.time) <
              new Date(endTime.setDate(endTime.getDate() - 7))
          ) {
            withdrawal.status = 3;
            return withdrawal;
          }
        }
        return withdrawal;
      });
    },
    ETicket: (state, action) => {
      state.withdrawalLogs = state.withdrawalLogs.map((withdrawal) => {
        if (withdrawal.status === 3) {
          let now = new Date();
          let endTime = getWednesdayOfWeek(now);
          endTime.setHours(0);
          endTime.setMinutes(0);
          endTime.setSeconds(0);
          if (
            (new Date(withdrawal.time) < endTime && endTime <= new Date()) ||
            new Date(withdrawal.time) <
              new Date(endTime.setDate(endTime.getDate() - 7))
          ) {
            withdrawal.status = 1;
            return withdrawal;
          }
        }
        return withdrawal;
      });
    },
  },
});
