import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";

import {ProductViewMemberProfileInformation} from "./ProductViewMemberProfileInformation"
const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
}));

export function ProductViewMemberProfileCard({ user }) {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={2} className={classes.card}>
        <CssBaseline />
        <ProductViewMemberProfileInformation user={user} />
      </Paper>
    </>
  );
}
