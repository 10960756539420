import { createSlice } from "@reduxjs/toolkit";

const initialWalletState = {
  actionsLoading: false,
  walletLogs: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: initialWalletState,
  reducers: {
    setWalletLogs: (state, action) => {
      state.actionsLoading = false;
      state.walletLogs = action.payload.walletLogs;
    },
    clearWalletLogs: (state, action) => {
      state.actionsLoading = false;
      state.walletLogs = [];
    },
  },
});
