import React,{useState,useEffect} from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography"
import groupList from "../../_redux/GroupList.json"
import { MaterialInput,MaterialCheckBox,AutocompleteComboBox } from "../../../../../_metronic/_partials/controls";


const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("電子信箱格式錯誤")
      .required("請輸入Email"),
    password: Yup.string()
      .min(6, "最少需要輸入6個字")
      .max(20, "最多只能輸入20個字")
      .required("請輸入密碼"),
    repeatPassword: Yup.string()
    .required("請輸入第二次密碼")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "兩次輸入的密碼不一致"),
    }),
    displayName: Yup.string()
    .min(1, "最少需要輸入1個字")
    .max(10, "最多只能輸入10個字")
    .required("請輸入暱稱"),
    groupId: Yup.number()
    .required("請選擇您來自哪裡"),
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancel: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: "#F44336",
    fontWeight: "bold",
  },
}));

const filterByCallback = (option, props) =>
  option.id.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
  option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;



export function RegistrationForm({ actionLoading, errorMessage, info, register,backToLogin }) {
  const classes = useStyles();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={info}
        validationSchema={RegistrationSchema}
        onSubmit={(values) => {
          register(values);
        }}
      >
        {({ handleSubmit, setFieldValue,setFieldTouched, values }) => (
          <>
            <Form className={classes.form} noValidate>
              <Field
                name="email"
                component={MaterialInput}
                label="帳號"
                id="email"
                autoComplete="email"
                autoFocus
                required
              />
              <Field
                name="password"
                component={MaterialInput}
                label="密碼"
                type="password"
                id="password"
                autoComplete="current-password"
                required
              />
              <Field
                name="repeatPassword"
                component={MaterialInput}
                label="確認密碼"
                type="password"
                id="repeatPassword"
                required
              />
              <Field
                name="displayName"
                component={MaterialInput}
                label="暱稱"
                id="displayName"
                autoComplete="displayName"
                required
              />
              <Field
                component={AutocompleteComboBox}
                id="groupId"
                name="groupId"
                label="您來自哪裡"
                placeholder="您來自哪裡"
                options={groupList}
                defaultValue={{name:"無",id:1}}
                setFieldValue={setFieldValue}
                />
              <Field
                name="acceptTerms"
                component={MaterialCheckBox}
                label="我同意條款與條件"
                url="https://quicktrade-image.aiv.com.tw/Terms-of-use.html"
                id="acceptTerms"
              />
              <Grid container justify="center">
                <Grid item >
                  <Typography className={classes.errorMessage} component="h1" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={6} >
                <Button
                    type="button"
                    fullWidth
                    disableElevation
                    variant="contained"
                    color="default"
                    className={classes.cancel}
                    onClick={() => backToLogin()}
                    disabled={actionLoading}
                  >
                    取消
                  </Button>
                </Grid>
                <Grid item xs={6} >
                <Button
                    type="submit"
                    fullWidth
                    disableElevation
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onSubmit={() => handleSubmit()}
                    disabled={actionLoading ||!values.acceptTerms}
                  >
                    註冊
                  </Button>
                </Grid>
              </Grid>  
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}