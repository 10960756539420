/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import "./AsideMenuList.css"
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import {
  MdAssignment,
  MdGroup,
  MdAccountBalanceWallet,
  MdPerson,
  MdCardGiftcard,
  MdInsertDriveFile,
  MdAccountBalance,
  MdContactPhone,
  MdLightbulbOutline,
  MdHistory,
  MdLocalShipping,
  MdShoppingCart,
} from "react-icons/md";
import { FaMapSigns } from "react-icons/fa";
import { RiFilePaper2Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const dispatch = useDispatch();
  const { authToken, user } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
      user: state.auth.user,
    }),
    shallowEqual
  );

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {user ? (
          user.roleId === 1 ? (
            <>
              <li className="menu-section ">
                <h4 className="menu-text">管理</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/seller/customers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/seller/customers">
                  <span className="svg-icon menu-icon">
                    <MdGroup/>
                  </span>
                  <span className="menu-text">平台用戶</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/manage/productManage"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/manage/productManage">
                  <span className="svg-icon menu-icon">
                    <MdCardGiftcard />
                  </span>
                  <span className="menu-text">商品管理</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/manage/withdrawal"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/manage/withdrawal">
                  <span className="svg-icon menu-icon">
                    <MdAccountBalance />
                  </span>
                  <span className="menu-text">提款紀錄</span>
                </NavLink>
              </li>
            </>
          ) : null
        ) : null}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">會員功能</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {user ? (
          user.roleId <= 4 ? (
            <>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/basic/profile"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/basic/profile">
                  <span className="svg-icon menu-icon">
                    <MdPerson />
                  </span>
                  <span className="menu-text">個人資料</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/basic/account-option"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/basic/account-option">
                  <span className="svg-icon menu-icon">
                    <MdAccountBalance />
                  </span>
                  <span className="menu-text">匯款帳戶設定</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/basic/wallet"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/basic/wallet">
                  <span className="svg-icon menu-icon">
                    <MdAccountBalanceWallet />
                  </span>
                  <span className="menu-text">快交益錢包</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/backend/basic/log")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/basic/log">
                  <span className="svg-icon menu-icon">
                    <MdHistory />
                  </span>
                  <span className="menu-text">錢包紀錄</span>
                </NavLink>
              </li>
            </>
          ) : null
        ) : null}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">我是買家</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {user ? (
          user.roleId <= 4 ? (
            <>
              {/*begin::1 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/seller/orders"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/seller/orders">
                  <span className="svg-icon menu-icon">
                    <MdShoppingCart />
                  </span>
                  <span className="menu-text">購物車</span>
                </NavLink>
              </li> */}
              {/*end::1 Level*/}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/buyer/history"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/buyer/history">
                  <span className="svg-icon menu-icon">
                    <MdHistory />
                  </span>
                  <span className="menu-text">歷史訂單</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
            </>
          ) : null
        ) : null}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">我是賣家</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {user ? (
          user.roleId === 4 ? (
            <>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/basic/seller-data-option"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/backend/basic/seller-data-option"
                >
                  <span className="svg-icon menu-icon">
                    <MdPerson />
                  </span>
                  <span className="menu-text">成為賣家</span>
                </NavLink>
              </li>
            </>
          ) : null
        ) : null}
        {user ? (
          user.roleId <= 3 ? (
            <>
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/seller/shippingMethods"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/seller/shippingMethods">
                  <span className="svg-icon menu-icon">
                    <MdLocalShipping />
                  </span>
                  <span className="menu-text">運送方式設定</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/seller/products"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/seller/products">
                  <span className="svg-icon menu-icon">
                    <MdCardGiftcard />
                  </span>
                  <span className="menu-text">商品管理</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/backend/seller/orders"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/backend/seller/orders">
                  <span className="svg-icon menu-icon">
                    <MdInsertDriveFile />
                  </span>
                  <span className="menu-text">訂單管理</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
            </>
          ) : null
        ) : null}

        {/* 使用條例 */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">快交益Q&A</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/backend/announcement/teaching")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/backend/announcement/teaching">
            <span className="svg-icon menu-icon">
              <MdLightbulbOutline />
            </span>
            <span className="menu-text">使用教學</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/backend/announcement/statute")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/backend/announcement/statute">
            <span className="svg-icon menu-icon">
              <MdAssignment />
            </span>
            <span className="menu-text">快交益使用規約</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/backend/announcement/rate")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/backend/announcement/rate">
            <span className="menu-icon">
              <RiFilePaper2Fill />
            </span>
            <span className="menu-text">提撥申請規約</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/backend/announcement/contact")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/backend/announcement/contact">
            <span className="svg-icon menu-icon">
              <MdContactPhone />
            </span>
            <span className="menu-text">聯絡客服</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
