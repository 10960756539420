import React from "react";
import { CheckoutItem } from "./CheckoutItem";

export function CheckoutItems({ checkoutItems }) {
  return (
    <>
      {checkoutItems
        ? checkoutItems.map((checkoutItem) => {
            return <CheckoutItem checkoutItem={checkoutItem} />;
          })
        : null}
    </>
  );
}
