import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";

const SellerPage = lazy(() => import("./modules/Seller/pages/SellerPage"));

const BasicPage = lazy(() => import("./modules/Basic/pages/BasicPage"));

const BuyerPage = lazy(() => import("./modules/Buyer/pages/BuyerPage"));

const AnnouncementPage = lazy(() =>
  import("./modules/Announcement/pages/AnnouncementPage")
);

const ManagePage = lazy(() => import("./modules/Manage/ManagePage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <>
      <Helmet>
        <title>快交益管理後台</title>
      </Helmet>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Redirect exact from="/backend" to="/backend/basic/profile" />
          <ContentRoute path="/backend/dashboard" component={DashboardPage} />
          <ContentRoute path="/backend/builder" component={BuilderPage} />
          <ContentRoute path="/backend/my-page" component={MyPage} />
          <Route path="/backend/seller" component={SellerPage} />
          <Route path="/backend/basic" component={BasicPage} />
          <Route path="/backend/announcement" component={AnnouncementPage} />
          <Route path="/backend/manage" component={ManagePage} />
          <Route path="/backend/buyer" component={BuyerPage} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    </>
  );
}
