import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";

const CheckoutInformationFormSchema = Yup.object().shape({
  memberEmail: Yup.string()
    .email("電子信箱格式錯誤")
    .required("請輸入購買人電子信箱"),
  memberDisplayName: Yup.string()
    .min(1, "最少需要輸入1個字")
    .max(10, "最多只能輸入10個字")
    .required("請輸入購買人"),
  name: Yup.string()
    .min(1, "最少需要輸入1個字")
    .max(10, "最多只能輸入10個字")
    .required("請輸入收貨人"),
  email: Yup.string()
    .email("電子信箱格式錯誤")
    .required("請輸入收貨人電子信箱"),
  phone: Yup.string()
    .min(10, "手機號碼長度不符")
    .max(10, "手機號碼長度不符")
    .required("請輸入收貨人手機號碼"),
  address: Yup.string()
    .min(1, "最少需要輸入1個字")
    .max(50, "最多只能輸入50個字")
    .required("請輸入收貨人地址"),
  psNote: Yup.string()
    .min(1, "最少需要輸入1個字")
    .max(50, "最多只能輸入50個字"),
});

export function CheckoutInformationForm({
  actionsLoading,
  checkoutInformation,
  createOrder,
  shippingMethods,
  user,
}) {
  const [shippingMethodId, setShippingMethodId] = useState(0);

  useEffect(() => {
    if (shippingMethods[0]) {
      setShippingMethodId(shippingMethods[0].shippingId);
    }
  }, [shippingMethods]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={checkoutInformation}
        validationSchema={CheckoutInformationFormSchema}
        onSubmit={(values) => {
          createOrder(values, shippingMethodId);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row mt-5 justify-content-center">
                <div className="col-lg-12">
                  <Select
                    name="shippingMethodId"
                    label="運送方式"
                    onChange={(e) => {
                      setShippingMethodId(+e.target.value);
                    }}
                    value={shippingMethodId ? shippingMethodId : 0}
                  >
                    {shippingMethods.map((shippingMethod) => {
                      if (shippingMethod&&shippingMethod.enabled) {
                        return (
                          <option value={shippingMethod.shippingId}>
                            {shippingMethod.name +
                              " 運費: " +
                              shippingMethod.price}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-12">
                  <Field
                    type="email"
                    name="memberEmail"
                    component={Input}
                    placeholder="電子信箱"
                    label="購買人電子信箱"
                  />
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-12">
                  <Field
                    type="text"
                    name="memberDisplayName"
                    component={Input}
                    placeholder="購買人"
                    label="購買人"
                  />
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-12">
                  <Field
                    type="text"
                    name="name"
                    component={Input}
                    placeholder="收貨人"
                    label="收貨人"
                  />
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-12">
                  <Field
                    type="email"
                    name="email"
                    component={Input}
                    placeholder="電子信箱"
                    label="收貨人電子信箱"
                  />
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-12">
                  <Field
                    type="text"
                    name="phone"
                    component={Input}
                    placeholder="手機號碼"
                    label="收貨人手機號碼"
                  />
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-12">
                  <Field
                    type="text"
                    name="address"
                    component={Input}
                    placeholder="收貨人地址"
                    label="收貨人地址"
                  />
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-12">
                  <Field
                    type="text"
                    name="psNote"
                    component={Input}
                    placeholder="備註"
                    label="備註"
                  />
                </div>
              </div>
              <div className="form-group row justify-content-end mr-1">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onSubmit={() => handleSubmit()}
                  disabled={actionsLoading}
                >
                  送出訂單
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
