import { createSlice } from "@reduxjs/toolkit";

const initialWalletLogsState = {
  actionsLoading: false,
  walletLogs: [],
  walletForm:{}
};
export const callTypes = {
  list: "list",
  action: "action",
};


export const walletLogsSlice = createSlice({
  name: "walletLogs",
  initialState: initialWalletLogsState,
  reducers: {
    setWalletLogs: (state, action) => {
      state.actionsLoading = false;
      state.walletLogs = action.payload.walletLogs;
    },
    pushWalletLogs: (state, action) => {
      state.actionsLoading = false;
      state.walletLogs.push(action.payload.walletLog);
    },
  },
});
