import * as requestFromServer from "./ShoppingCartCrud";
import { shoppingCartSlice, callTypes } from "./ShoppingCartSlice";
import * as CheckoutActions from "../Checkout/CheckoutActions";

const { actions } = shoppingCartSlice;

export const createShoppingCart = (request) => (dispatch) => {
  return requestFromServer.createShoppingCart(request).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.pushShoppingCarts({ pushShoppingCart: res.data }));
        break;
      default:
        break;
    }
  });
};

export const directPurchase = (request) => (dispatch) => {
  return requestFromServer.createShoppingCart(request).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.pushShoppingCarts({ pushShoppingCart: res.data }));
        dispatch(CheckoutActions.setCheckoutShoppingCartIds(res.data.id));
        break;
      default:
        break;
    }
  });
};


export const ghostDirectPurchase = (request) => (dispatch) => {
  return dispatch(CheckoutActions.setCheckOutItemTemp(request));

};

export const deleteShoppingCarts = (ids) => (dispatch) => {
  return dispatch(actions.deleteShoppingCarts({ ids: ids }));
};
