import React, { useState, useEffect } from "react";

export function CheckoutItem({ checkoutItem }) {
  return (
    <>
      <tr>
        <td className="d-flex align-items-center font-weight-bolder">
          <div className="symbol symbol-60 flex-shrink-0 mr-4 bg-light">
            <div
              className="symbol-label"
              style={{
                backgroundImage: checkoutItem
                  ? checkoutItem.imageId
                    ? `url(${process.env.REACT_APP_BACK_END}/image/${checkoutItem.imageId})`
                    : null
                  : null,
              }}
            ></div>
          </div>
          <a href="#" className="text-dark text-hover-primary">
            {checkoutItem ? checkoutItem.name : null}
          </a>
        </td>
        <td className="text-center align-middle">
          <span className="mr-2 font-weight-bolder">
            {checkoutItem ? checkoutItem.amount : 1}
          </span>
        </td>
        <td className="text-right align-middle font-weight-bolder font-size-h5">
          ${checkoutItem ? checkoutItem.price : 0}
        </td>
        <td className="text-right align-middle font-weight-bolder font-size-h5 text-primary">
          ${checkoutItem ? checkoutItem.amount * checkoutItem.price : 0}
        </td>
      </tr>
    </>
  );
}
