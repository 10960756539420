/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/Products/ProductsActions";
import { useProductViewUIContext } from "../ProductViewUIContext";
import { Modal } from "react-bootstrap";
import { ProductViewMemberProfileCard } from "./ProductViewMemberProfileCard";
import Container from "@material-ui/core/Container";

export function ProductViewMemberProfileDialog({ id, show, onHide }) {
  const productViewUIContext = useProductViewUIContext();
  const productsViewUIProps = useMemo(() => {
    return {
      initMemberProfile: productViewUIContext.initMemberProfile,
    };
  }, [productViewUIContext]);

  const dispatch = useDispatch();

  const { actionsLoading, userForShow } = useSelector(
    (state) => ({
      actionsLoading: state.customerProduct.actionsLoading,
      userForShow: state.customerProduct.userForShow,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch(actions.getUserById(id));
    }
  }, [id]);

  return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title"
      >
        <ProductViewMemberProfileCard
          user={userForShow || productsViewUIProps.initMemberProfile}
        />
      </Modal>
  );
}
