import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../app/modules/Auth/_redux/AuthAction";

export function Header({logoutClick,loginClick,registrationClick,backendClick}) {


  const dispatch = useDispatch();
  const { user, authToken } = useSelector(
    (state) => ({
      user: state.auth.user,
      authToken: state.auth.authToken,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.getUserByToken(authToken));
  }, [authToken]);



  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        style={{
          backgroundColor: "#1E1E2D",
        }}
      >
        <div className="container">
          {/* <NavLink className="navbar-brand" to="/">快交易</NavLink> */}
          <a href="https://quicktrade-image.aiv.com.tw/" target="_blank">
            <img
              src={require("./logo.png")}
              alt="logo"
              style={{
                maxWidth: "160px",
              }}
            ></img>
          </a>
          <div className="topbar">
            {authToken ? (
              <>
                <div className="topbar-item">
                  <div
                    className="w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    id="kt_quick_user_toggle"
                  >
                    <>
                      <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                        歡迎回來,
                      </span>
                      <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                        {user ? (user ? user.displayName : null) : null}
                      </span>
                    </>
                  </div>
                </div>
                <div className="topbar-item mr-2">
                  <button
                    className="btn btn-light-primary btn-bold"
                    onClick={logoutClick}
                  >
                    登出
                  </button>
                </div>
                <div className="topbar-item">
                  <button
                    className="btn btn-light-primary btn-bold"
                    onClick={backendClick}
                  >
                    管理後台
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="topbar-item mr-2">
                  <button className="btn btn-primary btn-bold" onClick={loginClick}>
                    登入
                  </button>
                </div>
                <div className="topbar-item ">
                  <button
                    className="btn btn-secondary btn-bold"
                    onClick={registrationClick}
                  >
                    立即註冊
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
