import React, { useState, useEffect } from "react";
import { CheckoutItemsListTable } from "./checkout-items-list-table/CheckoutItemsListTable";

export function CheckoutItemsListCard({ checkoutItems }) {
  return (
    <>
      <div className="card-body">
        <div className="table-responsive">
          <CheckoutItemsListTable checkoutItems={checkoutItems} />
        </div>
      </div>
    </>
  );
}
