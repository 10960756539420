import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import * as actions from "../../../_redux/Products/ProductsActions";
import * as authAction from "../../../../Auth/_redux/AuthAction";
import * as shoppingCartActions from "../../../_redux/ShoppingCart/ShoppingCartActions";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { ProductViewHeader } from "./product-view-header/ProductViewHeader";
import { ProductViewSlider } from "./product-view-slider/ProductViewSlider";
import { ProductViewDetail } from "./product-view-detail/ProductViewDetail";

const initUser = {
  id: 0,
  account: "",
  displayName: "",
  cellphone: "",
  identity: "",
  birthday: null,
  roleId: 0,
  groupId: 0,
  removed: false,
  activated: true,
  createDate: null,
};

const initProduct = {
  id: 0,
  memberId: 0,
  name: "",
  price: 0,
  amount: 0,
  status: 2,
  description: "",
  remarks: "",
  url: "",
  timeStart: null,
  timeEnd: null,
  removed: false,
  createDate: null,
  productShippingMethods: [],
  imageIds: [],
};

const initSeller = {
  id: 0,
  account: "",
  displayName: "",
  cellphone: "",
  identity: "",
  birthday: null,
  roleId: 0,
  groupId: 0,
  removed: false,
  activated: true,
  createDate: null,
};

const initShoppingCartForm = {
  productId: null,
  salesId: null,
  amount: 1,
};

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(2),
  },
}));

export function ProductViewCard({ history, url, salesId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user, authToken, product, seller, sales } = useSelector(
    (state) => ({
      user: state.auth.user,
      authToken: state.auth.authToken,
      product: state.customerProduct.product,
      seller: state.customerProduct.seller,
      sales: state.customerProduct.sales,
    }),
    shallowEqual
  );

  const addShoppingCart = (request) => {
    dispatch(shoppingCartActions.createShoppingCart(request));
  };

  const directPurchase = (request) => {
    if (authToken) {
      dispatch(shoppingCartActions.directPurchase(request)).then(() => {
        goToCheckoutPage();
      });
    } else {
      dispatch(shoppingCartActions.ghostDirectPurchase(request));
      goToCheckoutPage();
    }
  };

  const goToCheckoutPage = () => {
    history.push(`/customer/checkout`);
  };

  useEffect(() => {
    dispatch(authAction.getUserByToken());
    dispatch(actions.getProductByUrl(url));
    if (salesId) {
      dispatch(actions.getSalesById(salesId));
    }
  }, []);

  useEffect(() => {
    if (product) {
      dispatch(actions.getSellerById(product.memberId));
    }
  }, [product]);

  return (
    <>
      <Helmet>
        <title>{product ? product.name+" - 快交益" : null}</title>
      </Helmet>
      <Paper elevation={2} className={classes.card}>
        <CssBaseline />
        {sales ? (
          <div className="row justify-content-center">
            <ProductViewHeader url={url} sales={sales} />
          </div>
        ) : null}

        <div className="row justify-content-center">
          <div className="col-lg-5">
            <ProductViewSlider
              user={user || initUser}
              product={product || initProduct}
              seller={seller || initSeller}
            />
          </div>
          <div className="col-lg-7">
            <ProductViewDetail
              product={product || initProduct}
              salesId={salesId}
              shoppingCartForm={initShoppingCartForm}
              addShoppingCart={addShoppingCart}
              directPurchase={directPurchase}
            />
          </div>
        </div>
      </Paper>
    </>
  );
}
