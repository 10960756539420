import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { CustomerPage } from "./modules/Customer/pages/CustomerPage";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

export function CustomerBasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/customer" to="/customer/products" />
        <Route path="/customer" component={CustomerPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
