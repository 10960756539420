import React, { useState, useEffect } from "react";
import { CheckoutItems } from "./checkout-items/CheckoutItems";
export function CheckoutItemsListTable({ checkoutItems }) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let totalTemp = 0;

    checkoutItems.map((checkoutItem) => {
      totalTemp += checkoutItem.price * checkoutItem.amount;
    });
    setTotal(totalTemp);
  }, [checkoutItems]);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>商品</th>
            <th className="text-center">數量</th>
            <th className="text-center">單價</th>
            <th className="text-right">小結</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <CheckoutItems checkoutItems={checkoutItems ? checkoutItems : []} />
          <tr>
            <td colspan="2"></td>
            <td className="font-weight-bolder font-size-h4 text-right">
              總金額
            </td>
            <td className="font-weight-bolder font-size-h4 text-right text-primary">
              ${total}
            </td>
          </tr>
          <tr>
            <td colspan="4" className="border-0 text-muted text-right pt-0">
              不含運費
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
