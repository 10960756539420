/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { WelcomePage } from "./modules/Auth/pages/WelcomePage";
import { BackendRoutes } from "./BackendRoutes";
import { CustomerRoutes } from "./CustomerRoutes";

export function Routes() {
  return (
    <Switch>
      <Route path="/welcome/:token" component={WelcomePage} />
      <Route path="/backend" component={BackendRoutes} />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/" component={CustomerRoutes} />
    </Switch>
  );
}
