import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../_redux/AuthAction";
import { useHistory } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { RegistrationForm } from "./RegistrationForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const initInfo = {
  email: undefined,
  password: undefined,
  repeatPassword: undefined,
  displayName: undefined,
  groupId:"1",
  acceptTerms: false

};

const useStyles = makeStyles((theme) => ({
  card:{
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),  
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export function RegistrationPage() {
  const [actionLoading, setActionLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const register = (values) => {
    setActionLoading(true);
    dispatch(actions.register({account:values.email,password:values.password,displayName:values.displayName,groupId:values.groupId}, setActionLoading, setErrorMessage,backToLogin));
  };
  const backToLogin = () => {
    history.push("/backend/login");
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={actionLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={0}
          className={classes.card}
        >
          <CssBaseline />
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">註冊</h3>
            <p className="text-muted font-weight-bold">請填入以下資料</p>
          </div>
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
              {token ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
            </Avatar> */}
            <RegistrationForm
              actionLoading={actionLoading}
              errorMessage={errorMessage}
              info={initInfo}
              register={register}
              backToLogin={backToLogin}
            />
          </div>
        </Paper>
      </Container>
    </>
  );
}
