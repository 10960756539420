import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector,useDispatch } from "react-redux";
import * as actions from "../../_redux/AuthAction";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { LoginForm } from "./LoginForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const initAuth = {
  account: undefined,
  password: undefined,
};

const useStyles = makeStyles((theme) => ({
  card:{
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),  
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export function LoginPage() {
  const [actionLoading, setActionLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const login = (values) => {
    setActionLoading(true);
    dispatch(actions.login({account:values.email,password:values.password}, setActionLoading, setErrorMessage));
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={actionLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={0}
          className={classes.card}
        >
          <CssBaseline />
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">登入</h3>
          </div>
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
              {token ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
            </Avatar> */}
            <LoginForm
              actionLoading={actionLoading}
              errorMessage={errorMessage}
              auth={initAuth}
              login={login}
            />
          </div>
        </Paper>
      </Container>
    </>
  );
}
