import { createSlice } from "@reduxjs/toolkit";

const initialOrderState = {
  actionsLoading: false,
  orders: [],
  order:undefined,
  orderItem:undefined,
  orderDetails: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const OrderSlice = createSlice({
  name: "order",
  initialState: initialOrderState,
  reducers: {

    setOrders: (state, action) => {
      state.actionsLoading = false;
      state.orders = action.payload.orders;
    },

    setOrderDetails: (state, action) => {
      state.actionsLoading = false;
      state.orderDetails = action.payload.orderDetails;
    },

    setOrderItem: (state, action) => {
      state.actionsLoading = false;
      state.orderItem = action.payload.orderItem;
    },

    setOrder: (state, action) => {
      state.actionsLoading = false;
      state.order = action.payload.order;
    },

    pushOrders: (state, action) => {
      state.actionsLoading = false;
      state.orders.push(action.payload.order);
    },

  },
});
