import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AmountImputGroup } from "../../../../../../../_metronic/_partials/controls/forms/AmountImputGroup";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shippingMethods: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export function ProductViewDetail({
  product,
  salesId,
  shoppingCartForm,
  addShoppingCart,
  directPurchase,
}) {
  const [amount, setAmount] = useState(
    shoppingCartForm ? shoppingCartForm.amount : 1
  );

  const classes = useStyles();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={shoppingCartForm}
        onSubmit={(values) => {
          directPurchase({
            productId: product ? product.id : 0,
            salesId: salesId ? salesId : null,
            amount: amount,
          });
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <article className="p-5">
              <div className="mt-10 form-group row justify-content-left">
                <div className="col-lg-12">
                  <h1 className="title mb-3">
                    <b>{product ? product.name : null}</b>
                  </h1>
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="col-lg-4 mb-1">
                  <span className="h2 text-warning">
                    <span className="currency">$</span>
                    <span className="num">{product ? product.price : 0}</span>
                  </span>
                </div>
                <div className="col-lg-8 mb-1">
                  <span className="h4">
                    剩餘庫存: {product ? product.amount : 0}
                  </span>
                </div>
              </div>
              <div className="mt-5 form-group row justify-content-center">
                <div className="col-lg-12">
                  <label className="h5">運送方式</label>
                  <div className={classes.shippingMethods}>
                    {product
                      ? product.productShippingMethods.map(
                          (productShippingMethod) => {
                            if (
                              productShippingMethod &&
                              productShippingMethod.enabled
                            ) {
                              return (
                                <div className="m-1">
                                  <Chip
                                    label={
                                      "#" +
                                      productShippingMethod.name +
                                      " " +
                                      "運費:" +
                                      productShippingMethod.price +
                                      "元"
                                    }
                                  />
                                </div>
                              );
                            }
                          }
                        )
                      : null}
                  </div>
                </div>
              </div>
              <div className="mt-5 form-group row justify-content-center">
                <div className="col-lg-12">
                  <AmountImputGroup
                    value={amount}
                    setValue={setAmount}
                    maxValue={product ? product.amount : 0}
                    minValue={1}
                    label="購買數量"
                  />
                </div>
              </div>
              <div className="mt-5 form-group row justify-content-center">
                <div className="col-lg-12">
                  {product ? (
                    product.status === 0 ? (
                      <Button
                        onClick={() => {
                          handleSubmit();
                        }}
                        style={{ marginTop: "10px" }}
                        variant="primary"
                        block
                      >
                        <i
                          className="fa fa-shopping-cart mr-2"
                          aria-hidden="false"
                        />{" "}
                        直接購買
                      </Button>
                    ) : null
                  ) : null}
                  {product ? (
                    product.status === 1 ? (
                      <Button
                        onClick={null}
                        style={{ marginTop: "10px" }}
                        variant="danger"
                        disabled
                        block
                      >
                        <i
                          className="fa fa-shopping-cart mr-2"
                          aria-hidden="false"
                        />{" "}
                        已下架
                      </Button>
                    ) : null
                  ) : null}
                  {product ? (
                    product.status === 2 ? (
                      <Button
                        style={{ marginTop: "10px" }}
                        variant="secondary"
                        disabled
                        block
                      >
                        <i
                          className="fa fa-shopping-cart mr-2"
                          aria-hidden="false"
                        />{" "}
                        缺貨中
                      </Button>
                    ) : null
                  ) : null}
                  {product ? (
                    product.status === 3 ? (
                      Date.now() >= product.timeStart &&
                      Date.now() <= product.timeEnd ? (
                        <Button
                          onClick={() => {
                            handleSubmit();
                          }}
                          style={{ marginTop: "10px" }}
                          variant="primary"
                          block
                        >
                          <i
                            className="fa fa-shopping-cart mr-2"
                            aria-hidden="false"
                          />{" "}
                          直接購買
                        </Button>
                      ) : (
                        <Button
                          style={{ marginTop: "10px" }}
                          variant="info"
                          disabled
                          block
                        >
                          <i
                            className="fa fa-shopping-cart mr-2"
                            aria-hidden="false"
                          />{" "}
                          未開始或已經結束販售
                        </Button>
                      )
                    ) : null
                  ) : null}
                </div>
              </div>
              <p></p>
              <dl className="item-property mt-5">
                <dt className="h5">商品說明</dt>
                <dd>
                  <p className="h6 text-capitalize">
                    {product.description
                      ? product.description
                      : "這個賣家很懶 沒有任何說明"}
                  </p>
                </dd>
              </dl>
              <dl className="item-property mt-5">
                <dt className="h5">備註說明</dt>
                <dd>
                  <p
                    className="h6 text-capitalize"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {product.remarks
                      ? product.remarks
                      : "這個賣家很懶 沒有任何說明"}
                  </p>
                </dd>
              </dl>
            </article>
          </>
        )}
      </Formik>
    </>
  );
}
