import React, { useState, useEffect, useLayoutEffect } from "react";
import renderHTML from "react-render-html";
import { CheckoutInformationForm } from "./CheckoutInformationForm";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as actions from "../../../_redux/Checkout/CheckoutActions";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const initCheckInformation = {
  memberEmail: "",
  memberDisplayName: "",
  name: "",
  phone: "",
  address: "",
  psNote: "",
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export function CheckoutInformationCard({ checkoutItems }) {
  const [shippingMethods, setShippingMethods] = useState();
  const [returnForm, setReturnForm] = useState();
  const [actionsLoading, setActionsLoading] = useState(false);

  const el = document.createElement("div");
  el.innerHTML = returnForm;

  useLayoutEffect(() => {
    eval(returnForm ? el.lastChild.innerText : null);
  }, [returnForm]);

  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    user,
    authToken,
    checkoutShoppingCartIds,
    checkoutItemTemp,
  } = useSelector(
    (state) => ({
      user: state.auth.user,
      authToken: state.auth.authToken,
      checkoutShoppingCartIds: state.customerCheckout.checkoutShoppingCartIds,
      checkoutItemTemp: state.customerCheckout.checkoutItemTemp,
    }),
    shallowEqual
  );

  const createOrder = (value, shippingMethodId) => {
    if (authToken) {
      dispatch(
        actions.createOrder(
          {
            name: value.name,
            phone: value.phone,
            email: value.email,
            address: value.address,
            shippingMethodId: shippingMethodId,
            psNote: value.psNote,
            shoppingCartIds: checkoutShoppingCartIds,
          },
          setReturnForm,
          setActionsLoading
        )
      );
    } else {
      dispatch(
        actions.createGhostOrder(
          {
            name: value.name,
            phone: value.phone,
            email: value.email,
            address: value.address,
            shippingMethodId: shippingMethodId,
            psNote: value.psNote,
            memberEmail: value.memberEmail,
            memberDisplayName: value.memberDisplayName,
          },
          setReturnForm,
          checkoutItemTemp,
          setActionsLoading
        )
      );
    }
  };

  useEffect(() => {
    let shippingMethodsTemp = [];
    if (checkoutItems) {
      checkoutItems.map((checkoutItem, index) => {
        if (checkoutItem) {
          if (index === 0) {
            checkoutItem.productShippingMethods.map((productShippingMethod) => {
              shippingMethodsTemp.push(productShippingMethod);
            });
          } else {
            shippingMethodsTemp.filter(function(element, index, arr) {
              return arr.indexOf(element) !== index;
            });
          }
        }
      });
    }
    setShippingMethods(shippingMethodsTemp);
  }, [checkoutItems]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={actionsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CheckoutInformationForm
        actionsLoading={actionsLoading}
        checkoutInformation={initCheckInformation}
        createOrder={createOrder}
        shippingMethods={shippingMethods || []}
        user={user}
      />
      {returnForm ? renderHTML(returnForm) : null}
    </>
  );
}
