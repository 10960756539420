import React, { createContext, useContext, useState, useCallback } from "react";

const ProductViewUIContext = createContext();

export function useProductViewUIContext() {
  return useContext(ProductViewUIContext);
}

export const ProductViewUIConsumer = ProductViewUIContext.Consumer;

export function ProductViewUIProvider({ productViewUIEvents, children }) {
  const initMemberProfile = {
    id: 0,
    account: "",
    name: "",
    displayName: "",
    cellphone: "",
    identity: "",
    birthday: null,
    roleId: 4,
    groupId: 1,
    removed: false,
    activated: true,
    createDate: null,
  };

  const value = {
    initMemberProfile,
  };

  return (
    <ProductViewUIContext.Provider value={value}>
      {children}
    </ProductViewUIContext.Provider>
  );
}
