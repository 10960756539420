import React, { useMemo } from "react";
import { Link } from "react-router-dom";
export function ProductViewHeader({ url, sales }) {
  return (
    <>
      <div className="mb-2">
        <Link
          to={`/customer/product/${url}/${sales ? sales.id : 0}/profile`}
          className="h1"
        >
          <b>{sales ? sales.displayName : null}</b>
        </Link>
        <span className="h1" style={{ color: "red" }}>
          <b>強烈推薦此商品!!</b>
        </span>
      </div>
    </>
  );
}
