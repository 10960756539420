import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Carousel from "react-bootstrap/Carousel";
import { MdImage, MdLink } from "react-icons/md";
import {
  FacebookShareButton,
  LineShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import { FacebookIcon, LineIcon, TelegramIcon, TwitterIcon } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ip = process.env.REACT_APP_BACK_END;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  share: {
    marginTop: theme.spacing(2),
  },
  shareButton: {
    marginTop: theme.spacing(2),
  },
  sellerInformation: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

export function ProductViewSlider({ user, product, seller }) {
  const [carouselIndex, setCarouselIndex] = useState();
  const [openCopyAlert, setOpenCopyAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenCopyAlert(false);
  };

  const classes = useStyles();

  const handleSelect = (selectedIndex, e) => {
    setCarouselIndex(selectedIndex);
  };

  const getImageCount = () => {
    let count = 0;
    product.imageIds.map((id) => {
      if (id === 0) {
        count++;
      }
    });

    return count !== product.imageIds.length;
  };

  return (
    <>
      <Snackbar
        open={openCopyAlert}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          <h3>已順利複製此商品連結!</h3>
        </Alert>
      </Snackbar>
      <Container component="div" maxWidth="lg">
        {product ? (
          getImageCount() ? (
            <Carousel activeIndex={carouselIndex} onSelect={handleSelect}>
              {product.imageIds.map((id, index) => {
                if (id) {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        src={ip + "/image/" + id}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Carousel.Item>
                  );
                } else {
                  return (
                    <Carousel.Item key={index}>
                      <MdImage
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "#E4E6EF",
                        }}
                      />
                    </Carousel.Item>
                  );
                }
              })}
            </Carousel>
          ) : (
            <MdImage
              style={{
                width: "100%",
                height: "100%",
                color: "#E4E6EF",
              }}
            />
          )
        ) : (
          <MdImage
            style={{
              width: "100%",
              height: "100%",
              color: "#E4E6EF",
            }}
          />
        )}
      </Container>
      <Container component="div" maxWidth="lg">
        <Grid
          className="mt-2"
          container
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {product
            ? product.imageIds.length > 0
              ? product.imageIds.map((id, index) => {
                  if (id) {
                    return (
                      <Grid key={index} item>
                        <div
                          onClick={() => {
                            setCarouselIndex(index);
                          }}
                        >
                          <img
                            src={ip + "/image/" + id}
                            style={{ maxWidth: "64px", maxHeight: "64px" }}
                          />
                        </div>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid key={index} item>
                        <MdImage
                          style={{
                            width: "64px",
                            height: "64px",
                            color: "#E4E6EF",
                          }}
                        />
                      </Grid>
                    );
                  }
                })
              : null
            : null}
        </Grid>
      </Container>
      <Container className={classes.share} component="div" maxWidth="xs">
        <Grid item>
          <h5>推銷員分享:</h5>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid>
            <div>
              <h6 style={{ color: "red" }}>
                推銷商品成功售出後，即可享有{Math.round(product.price * 0.04)}
                元回饋金!!
              </h6>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.shareButton} component="div" maxWidth="xs">
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <FacebookShareButton
              hashtag={product ? "#" + product.name : null}
              url={
                `${process.env.REACT_APP_FRONT_END}/customer/product/` +
                product.url +
                "/" +
                user.id
              }
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </Grid>
          <Grid item>
            <LineShareButton
              title={product ? product.name + " - 快交益" : null}
              url={
                `${process.env.REACT_APP_FRONT_END}/customer/product/` +
                product.url +
                "/" +
                user.id
              }
            >
              <LineIcon size={32} round={true} />
            </LineShareButton>
          </Grid>
          <Grid item>
            <TwitterShareButton
              title={product ? product.name + " - 快交益" : null}
              url={
                `${process.env.REACT_APP_FRONT_END}/customer/product/` +
                product.url +
                "/" +
                user.id
              }
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </Grid>
          <Grid item>
            <TelegramShareButton
              title={product ? product.name + " - 快交益" : null}
              url={
                `${process.env.REACT_APP_FRONT_END}/customer/product/` +
                product.url +
                "/" +
                user.id
              }
            >
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
          </Grid>
          <Grid item>
            <CopyToClipboard
              text={
                `${process.env.REACT_APP_FRONT_END}/customer/product/` +
                product.url +
                "/" +
                user.id
              }
            >
              <Avatar
                style={{ width: "32px", height: "32px" }}
                onClick={() => {
                  setOpenCopyAlert(true);
                }}
              >
                <IconButton>
                  <MdLink />
                </IconButton>
              </Avatar>
            </CopyToClipboard>
          </Grid>
        </Grid>
      </Container>
      <Container
        className={classes.sellerInformation}
        component="div"
        maxWidth="xs"
      >
        <Grid item>
          <h5>負責人: {seller.displayName}</h5>
        </Grid>
        <Grid item>
          <h5>聯絡電話: {seller.cellphone}</h5>
        </Grid>
        <Grid item>
          <h5>電子信箱: {seller.account}</h5>
        </Grid>
      </Container>
    </>
  );
}
