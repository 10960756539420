import React from "react";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function AmountImputGroup({
  value,
  setValue,
  maxValue,
  minValue,
  label,
}) {
  const checkAmountChange = (amount) => {
    if (amount > maxValue) {
      return maxValue;
    } else if (amount < minValue) {
      return minValue;
    }
    return amount;
  };
  return (
    <>
      <label className="h5">{label}</label>
      <div className="row">
        <div className="col-lg-12">
          <InputGroup>
            <InputGroup.Prepend>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setValue(checkAmountChange(value - 1));
                }}
              >
                -
              </Button>
            </InputGroup.Prepend>
            <FormControl
              placeholder="購買數量"
              value={value}
              style={{ textAlign: "center" }}
              onChange={(e) => {
                setValue(checkAmountChange(+e.target.value));
              }}
            />
            <InputGroup.Append>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setValue(checkAmountChange(value + 1));
                }}
              >
                +
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </div>
    </>
  );
}
