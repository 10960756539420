import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import {
  UserRoleCssClasses,
  UserRoleTitles,
  UserGroupTitles,
} from "../ProductViewUIHelpers";

const ip = process.env.REACT_APP_BACK_END;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export function ProductViewMemberProfileInformation({ user }) {
  const classes = useStyles();
  return (
    <>
      <div className="mb-5 mb-xl-8">
        <div className="card-body pt-15">
          <div className="d-flex flex-center flex-column mb-5">
            <Avatar
              alt={user ? user.displayName : null}
              src={user ? ip + "/image/" + user.imageId : null}
              className={classes.large}
            />
            <span className="h2 mt-2">{user ? user.displayName : null}</span>

            <div className="fs-5 fw-bold text-gray-400 mb-6">
              <span
                className={`label label-lg label-light-${
                  UserRoleCssClasses[user ? user.roleId : 0]
                } label-inline`}
              >
                {UserRoleTitles[user ? user.roleId : 0]}
              </span>
            </div>

            {/* <div className="d-flex flex-wrap flex-center">
              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                <div className="fs-4 fw-bolder text-gray-700">
                  <span className="w-75px">6,900</span>

                  <span className="svg-icon svg-icon-3 svg-icon-success"></span>
                </div>
                <div className="fw-bold text-gray-400">Earnings</div>
              </div>

              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                <div className="fs-4 fw-bolder text-gray-700">
                  <span className="w-50px">130</span>

                  <span className="svg-icon svg-icon-3 svg-icon-danger"></span>
                </div>
                <div className="fw-bold text-gray-400">Tasks</div>
              </div>

              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                <div className="fs-4 fw-bolder text-gray-700">
                  <span className="w-50px">500</span>

                  <span className="svg-icon svg-icon-3 svg-icon-success"></span>
                </div>
                <div className="fw-bold text-gray-400">Hours</div>
              </div>
            </div> */}
          </div>

          <div className="d-flex flex-stack fs-4 py-3">
            <div
              className="fw-bolder rotate collapsible"
              data-bs-toggle="collapse"
              href="#kt_customer_view_details"
              role="button"
              aria-expanded="false"
              aria-controls="kt_customer_view_details"
            >
              <span className="ms-2 rotate-180">
                <span className="svg-icon svg-icon-3"></span>
              </span>
            </div>
          </div>

          <div className="separator separator-dashed my-3"></div>

          <div id="kt_customer_view_details" className="collapse show">
            <div className="py-5 fs-6">
              <div className="mt-5">
                <b>電子信箱</b>
              </div>
              <div className="text-gray-600">{user ? user.account : null}</div>
              {user ? (
                user.roleId < 4 ? (
                  <>
                    <div className="mt-5">
                      <b>聯絡電話</b>
                    </div>
                    <div className="text-gray-600">{user.cellphone}</div>
                  </>
                ) : null
              ) : null}

              <div className="mt-5">
                <b>所屬群組</b>
              </div>
              <div className="text-gray-600">
                {UserGroupTitles[user ? user.groupId : 0]}
              </div>
              <div className="mt-5">
                <b>自我介紹</b>
              </div>
              <div className="text-gray-600">
                {user ? user.introduction : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
