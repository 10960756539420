import { createSlice } from "@reduxjs/toolkit";

const initialCustomerProductState = {
  actionsLoading: false,
  product: undefined,
  seller: undefined,
  sales: undefined,
  userForShow: undefined,

};
export const callTypes = {
  list: "list",
  action: "action",
};

export const customerProductSlice = createSlice({
  name: "customerProduct",
  initialState: initialCustomerProductState,
  reducers: {
    setProduct: (state, action) => {
      state.actionsLoading = false;
      state.product = action.payload.product;
    },
    setSeller: (state, action) => {
      state.actionsLoading = false;
      state.seller = action.payload.seller;
    },
    setSales: (state, action) => {
      state.actionsLoading = false;
      state.sales = action.payload.sales;
    },
    setUserForShow: (state, action) => {
      state.actionsLoading = false;
      state.userForShow = action.payload.userForShow;
    },
  },
});
