import * as requestFromServer from "./CheckoutCrud";
import { customerCheckoutSlice, callTypes } from "./CheckoutSlice";
import * as ShoppingCartActions from "../ShoppingCart/ShoppingCartActions";

const { actions } = customerCheckoutSlice;

export const setCheckoutShoppingCartIds = (id) => (dispatch) => {
  dispatch(
    actions.setCheckoutShoppingCartIds({
      checkoutShoppingCartIds: [id],
    })
  );
};

export const setCheckOutItemTemp = (request) => (dispatch) => {
  dispatch(actions.setCheckoutItemTemp({ checkoutItemTemp: request }));
};

export const getSellerById = (id) => (dispatch) => {
  return requestFromServer.getUserById(id).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.setSeller({ seller: res.data }));
        break;
      default:
        break;
    }
  });
};

export const pushCheckoutShoppingCartById = (id) => (dispatch) => {
  return requestFromServer.getShoppingCartById(id).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(
          actions.pushCheckoutShoppingCarts({ checkoutShoppingCart: res.data })
        );
        break;
      default:
        break;
    }
  });
};

export const pushCheckoutItemByShoppingCart = (checkShoppingCart) => (
  dispatch
) => {
  return requestFromServer
    .getProductById(checkShoppingCart.productId)
    .then((response) => {
      const res = response.data;
      switch (res.code) {
        case "00000":
          let imageIdTemp = null;
          res.data.imageIds.map((imageId) => {
            if (!imageIdTemp && imageId) {
              imageIdTemp = imageId;
            }
          }, []);
          dispatch(
            actions.pushCheckoutItems({
              checkoutItem: {
                name: res.data.name,
                amount: checkShoppingCart.amount,
                price: res.data.price,
                imageId: imageIdTemp,
                productShippingMethods: res.data.productShippingMethods,
              },
            })
          );
          break;
        default:
          break;
      }
    });
};

export const pushCheckoutItemByCheckoutItemTemp = (checkoutItemTemp) => (
  dispatch
) => {
  return requestFromServer
    .getProductById(checkoutItemTemp.productId)
    .then((response) => {
      const res = response.data;
      switch (res.code) {
        case "00000":
          let imageIdTemp = null;
          res.data.imageIds.map((imageId) => {
            if (!imageIdTemp && imageId) {
              imageIdTemp = imageId;
            }
          }, []);
          dispatch(
            actions.pushCheckoutItems({
              checkoutItem: {
                name: res.data.name,
                amount: checkoutItemTemp.amount,
                price: res.data.price,
                imageId: imageIdTemp,
                productShippingMethods: res.data.productShippingMethods,
              },
            })
          );
          break;
        default:
          break;
      }
    });
};

export const clearCheckoutShoppingCartIds = () => (dispatch) => {
  dispatch(actions.clearCheckoutShoppingCartIds());
};

export const clearCheckoutShoppingCarts = () => (dispatch) => {
  return dispatch(actions.clearCheckoutShoppingCarts());
};

export const clearCheckoutItems = () => (dispatch) => {
  return dispatch(actions.clearCheckoutItems());
};

export const clearCheckoutItemTemp = () => (dispatch) => {
  return dispatch(actions.clearCheckoutItemTemp());
};

export const createOrder = (request, setReturnForm, setActionsLoading) => (
  dispatch
) => {
  setActionsLoading(true);
  return requestFromServer.createOrder(request).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.clearCheckoutShoppingCarts());
        dispatch(actions.clearCheckoutItems());
        dispatch(
          ShoppingCartActions.deleteShoppingCarts(request.shoppingCartIds)
        );
        requestFromServer.createPayment([res.data.id]).then((response) => {
          setActionsLoading(false);
          const EcpayResponse = response.data;
          setReturnForm(EcpayResponse.data.html);
        });
        break;
      default:
        setActionsLoading(false);
        break;
    }
  });
};

export const createGhostOrder = (
  request,
  setReturnForm,
  checkoutItemTemp,
  setActionsLoading
) => (dispatch) => {
  setActionsLoading(true);
  return requestFromServer
    .ghostRegister({
      account: request.memberEmail,
      displayName: request.memberDisplayName,
    })
    .then((response) => {
      const res = response.data;
      switch (res.code) {
        case "00000":
          requestFromServer
            .createShoppingCart(checkoutItemTemp, res.data.token)
            .then((response) => {
              const shippingCartRes = response.data;
              switch (shippingCartRes.code) {
                case "00000":
                  dispatch(actions.clearCheckoutItemTemp());
                  const req = {
                    name: request.name,
                    phone: request.phone,
                    email: request.email,
                    address: request.address,
                    shippingMethodId: request.shippingMethodId,
                    psNote: request.psNote,
                    shoppingCartIds: [shippingCartRes.data.id],
                  };

                  requestFromServer
                    .createGhostOrder(req, res.data.token)
                    .then((response) => {
                      const OrderResponse = response.data;
                      switch (OrderResponse.code) {
                        case "00000":
                          requestFromServer
                            .createPayment([OrderResponse.data.id])
                            .then((response) => {
                              setActionsLoading(false);
                              setReturnForm(response.data);
                            });
                          break;
                        default:
                          setActionsLoading(false);
                          break;
                      }
                    });
                  break;
                default:
                  setActionsLoading(false);
                  break;
              }
            });
          break;
        case "20101":
          setActionsLoading(false);
          alert("此Email已經註冊，請登入會員重新購買");
          break;
        default:
          setActionsLoading(false);
          break;
      }
    });
};

export const ghostToMemberShoppingCart = (request) => (dispatch) => {
  console.log(request);
  return requestFromServer.createShoppingCart(request).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        console.log(res);
        dispatch(
          actions.setCheckoutShoppingCartIds({
            checkoutShoppingCartIds: [res.data.id],
          })
        );
        break;
      default:
        break;
    }
  });
};
