import React from "react";
import { Modal } from "react-bootstrap";
import { LoginCard } from "./LoginCard";
export function LoginDialog({ show, onHide }) {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <LoginCard onHide={onHide}/>
      </Modal>
    </>
  );
}
