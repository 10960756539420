import * as requestFromServer from "./AuthCrud";
import { actions } from "./AuthRedux";

export const login = (request, setActionLoading, setErrorMessage) => (
  dispatch
) => {
  return requestFromServer.login(request).then((response) => {
    setActionLoading(false);
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.login({ session: res.data }));
        break;
      default:
        setErrorMessage(res.message);
    }
  });
};

export const logout = () => (dispatch) => {
  return requestFromServer.logout().then((response) => {
    dispatch(actions.logout());
  });
};

export const getUserByToken = () => (dispatch) => {
  return requestFromServer.getUserByToken().then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.getUserByToken({ user: res.data }));
        break;
      default:
        break;
    }
  });
};

export const updateUser = (id, request, setActionLoading) => (dispatch) => {
  setActionLoading(true);
  return requestFromServer.updateMember(id, request).then((response) => {
    setActionLoading(false);
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.updateUser({ user: res.data }));
        break;
      default:
        break;
    }
  });
};

export const register = (
  request,
  setActionLoading,
  setErrorMessage,
  backToLogin
) => (dispatch) => {
  return requestFromServer.register(request).then((response) => {
    setActionLoading(false);
    const res = response.data;
    switch (res.code) {
      case "00000":
        alert("請至註冊信箱接收認證信");
        backToLogin();
        break;
      default:
        setErrorMessage(res.message);
    }
  });
};

export const changePassword = (
  request,
  setActionLoading,
  openErrorAlert,
  backToOverView
) => (dispatch) => {
  setActionLoading(true);
  return requestFromServer.changePassword(request).then((response) => {
    setActionLoading(false);
    const res = response.data;
    switch (res.code) {
      case "00000":
        backToOverView();
        break;
      default:
        openErrorAlert(res.message);
        break;
    }
  });
};

export const verifyEmail = (token) => (dispatch) => {
  return requestFromServer.verifyEmail(token);
};
