import React, { useEffect } from "react";
import { Route } from "react-router-dom";

import { ProductViewUIProvider } from "./ProductViewUIContext";
import { ProductViewMemberProfileDialog } from "./product-view-member-profile-dialog/ProductViewMemberProfileDialog";
import { ProductViewCard } from "./ProductViewCard";

export function ProductViewPage({
  history,
  match: {
    params: { url, salesId },
  },
}) {
  const productViewUIEvents = {};

  return (
    <>
      <ProductViewUIProvider productViewUIEvents={productViewUIEvents}>
        <Route path="/customer/product/:url/:id/profile">
          {({ history, match }) => (
            <ProductViewMemberProfileDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(
                  `/customer/product/${match && match.params.url}/${match &&
                    match.params.id}`
                );
              }}
            />
          )}
        </Route>
        <ProductViewCard history={history} url={url} salesId={salesId} />
      </ProductViewUIProvider>
    </>
  );
}
