import React from "react";

export function CheckoutHeader() {
  return (
    <>
      <div className="card-header flex-wrap border-0 pt-6 pb-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder font-size-h1 text-dark">
            結帳
          </span>
        </h3>
      </div>
    </>
  );
}
