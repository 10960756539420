import React, {useState,useEffect} from "react";
import { shallowEqual, useSelector,useDispatch } from "react-redux";
import * as actions from "../../Auth/_redux/AuthAction";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export function Logout(){
  const [actionLoading, setActionLoading] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();

  const { authToken } = useSelector(
    (state) => ({
      authToken: state.auth.authToken
    }),
    shallowEqual
  );
  const logout = () => {
    setActionLoading(true);
    dispatch(actions.logout());
  };

  useEffect(()=>{
    logout()
  },[authToken])

  return (
    <Backdrop className={classes.backdrop} open={actionLoading}>
        <CircularProgress color="inherit" />
    </Backdrop>
  )
  
}