import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/AuthAction";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function WelcomePage({
  match: {
    params: { token },
  },
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.verifyEmail(token));
  }, [token]);

  return (
    <>
      <div className="d-flex flex-column flex-root card">
        <div
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/illustrations/progress-hd.png"
            )})`,
          }}
        >
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
            <a href="/backend" className="mb-10 pt-lg-20">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-light.png")}
                className=" mb-3"
                style={{ height: "100%", maxHeight: "150px" }}
              />
            </a>
            <div className="pt-lg-10">
              <h1 className="fw-bolder fs-2qx text-dark mb-7">
                歡迎加入快交益
              </h1>
              <div className="fw-bold fs-3 text-gray-400 mb-15">
                您的電子信箱已驗證完畢
                <br />
                完成身分驗證後便可以上架商品進行販售
              </div>
              <div className="text-center">
                <a href="/backend" className="btn btn-lg btn-primary fw-bolder">
                  點擊登入
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
