import React from "react";
import { CheckoutCard } from "./CheckoutCard";

export function CheckoutPage() {
  return (
    <>
      <CheckoutCard />
    </>
  );
}
