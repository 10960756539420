import { createSlice } from "@reduxjs/toolkit";

const initialAccountState = {
  actionsLoading: false,
  accounts: [],
  accountForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const accountSlice = createSlice({
  name: "account",
  initialState: initialAccountState,
  reducers: {
    setAccounts: (state, action) => {
      state.actionsLoading = false;
      state.accounts = action.payload.accounts;
    },
    setAccountForEdit: (state, action) => {
      state.actionsLoading = false;
      state.accountForEdit = action.payload.account;
    },
    setProducts: (state, action) => {
      state.actionsLoading = false;
      state.accounts = action.payload.products;
    },
    pushAccounts: (state, action) => {
      state.actionsLoading = false;
      state.accounts.push(action.payload.account);
    },

    putAccounts: (state, action) => {
      state.actionsLoading = false;
      state.products = state.accounts.map((account) => {
        if (account.id === action.payload.account.id) {
          return action.payload.account;
        }
        return account;
      });
    },
    deleteid: (state, action) => {
      state.actionsLoading = false;
      state.accounts = state.accounts.filter(
        (el) => el.id !== action.payload.id
      );
    },
  }
});
