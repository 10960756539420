import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { MaterialInput } from "../../../../../_metronic/_partials/controls/forms/MaterialInput";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("電子信箱格式錯誤")
    .required("請輸入電子信箱"),
  password: Yup.string()
    .min(6, "密碼字數不足，最少6個字")
    .max(20, "密碼字數過多，最多20個字")
    .required("請輸入密碼"),
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: "#F44336",
    fontWeight: "bold",
  },
}));

export function LoginForm({ actionLoading, errorMessage, auth, login }) {
  const classes = useStyles();
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={auth}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          login(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className={classes.form} noValidate>
              <Field
                name="email"
                component={MaterialInput}
                label="帳號"
                id="email"
                autoComplete="email"
                autoFocus
                required
              />
              <Field
                name="password"
                component={MaterialInput}
                label="密碼"
                type="password"
                id="password"
                autoComplete="current-password"
                required
              />
              <Grid container justify="center">
                <Grid item>
                  <Typography
                    className={classes.errorMessage}
                    component="h1"
                    variant="body2"
                  >
                    {errorMessage}
                  </Typography>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
                className={classes.submit}
                onSubmit={() => handleSubmit()}
                disabled={actionLoading}
              >
                登入
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    忘記密碼?
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
