import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_customer/layout/Layout";
import { CustomerBasePage } from "./CustomerBasePage";

export function CustomerRoutes() {
  return (
    <Switch>
      <Redirect from="/" exact={true} to="/customer" />
      <Layout>
        <CustomerBasePage />
      </Layout>
    </Switch>
  );
}
