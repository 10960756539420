import axios from "axios";
import store from "../../../../redux/store";

export const ip = process.env.REACT_APP_BACK_END;
// export const ip = "/api"

export const LOGIN_URL = ip + "/session";
export const LOGOUT_URL = ip + "/session";
export const REGISTER_URL = ip + "/member";
export const REQUEST_PASSWORD_URL = ip + "/user/forget-password";
export const CHANGE_PASSWORD_URL = ip + "/member/password";
export const ME_URL = ip + "/member/token";
export const UPDATE_MEMBER_URL = ip + "/member";
export const VERIFYEMAIL = ip + "/member/verify/email";

const token = store.getState().auth.authToken;

export function login(request) {
  return axios.post(LOGIN_URL, request);
}

export function logout() {
  const headers = {
    Authorization: token,
  };
  return axios.delete(LOGOUT_URL, { headers: headers });
}

export function register(info) {
  return axios.post(REGISTER_URL, { ...info });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function changePassword(request) {
  const headers = {
    Authorization: token,
  };
  return axios.patch(CHANGE_PASSWORD_URL, request, { headers: headers });
}

export function getUserByToken() {
  const headers = {
    Authorization: token,
  };
  return axios.get(ME_URL, { headers: headers });
}

export function updateMember(id, request) {
  const headers = {
    Authorization: token,
  };
  return axios.put(UPDATE_MEMBER_URL + "/" + id, request, { headers: headers });
}

export function verifyEmail(authToken) {
  const headers = {
    Authorization: authToken,
  };
  return axios.patch(VERIFYEMAIL, null, { headers: headers });
}
