import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../_redux/Checkout/CheckoutActions";

import { makeStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

import { CheckoutHeader } from "./checkout-header/CheckoutHeader";
import { CheckoutItemsListCard } from "./checkout-items-list/CheckoutItemsListCard";
import { CheckoutInformationCard } from "./checkout-information/CheckoutInformationCard";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(2),
  },
}));

export function CheckoutCard() {
  const [actionLoading, setActionLoading] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    user,
    authToken,
    checkoutShoppingCartIds,
    checkoutShoppingCarts,
    checkoutItems,
    checkoutItemTemp,
  } = useSelector(
    (state) => ({
      user: state.auth.user,
      authToken: state.auth.authToken,
      checkoutShoppingCartIds: state.customerCheckout.checkoutShoppingCartIds,
      checkoutShoppingCarts: state.customerCheckout.checkoutShoppingCarts,
      checkoutItems: state.customerCheckout.checkoutItems,
      checkoutItemTemp: state.customerCheckout.checkoutItemTemp,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (checkoutShoppingCartIds) {
      dispatch(actions.clearCheckoutShoppingCarts());
      checkoutShoppingCartIds.map((checkoutShoppingCartId) => {
        dispatch(actions.pushCheckoutShoppingCartById(checkoutShoppingCartId));
      });
    }
  }, [checkoutShoppingCartIds]);

  useEffect(() => {
    if (checkoutShoppingCarts) {
      dispatch(actions.clearCheckoutItems());
      checkoutShoppingCarts.map((checkoutShoppingCart) => {
        dispatch(actions.pushCheckoutItemByShoppingCart(checkoutShoppingCart));
      });
    }
  }, [checkoutShoppingCarts]);

  useEffect(() => {
    if (checkoutItemTemp) {
      dispatch(actions.clearCheckoutItems());
      dispatch(actions.pushCheckoutItemByCheckoutItemTemp(checkoutItemTemp));
    }
  }, [checkoutItemTemp]);

  useEffect(() => {
    dispatch(actions.clearCheckoutItems());
    if (checkoutItemTemp && authToken) {
      dispatch(actions.ghostToMemberShoppingCart(checkoutItemTemp));
      dispatch(actions.clearCheckoutItemTemp());
    } else if (!checkoutItemTemp && !authToken) {
      dispatch(actions.clearCheckoutShoppingCartIds());
      history.goBack()
    }
  }, [authToken]);

  return (
    <>
      <Paper elevation={2} className={classes.card}>
        <CssBaseline />
        <Container maxWidth="md">
          <div className="justify-content-center">
            <div className="card-custom gutter-b">
              <CheckoutHeader />
              <div className="card-toolbar">
                <CheckoutItemsListCard checkoutItems={checkoutItems} />
                <CheckoutInformationCard checkoutItems={checkoutItems} />
              </div>
            </div>
          </div>
        </Container>
      </Paper>
    </>
  );
}
