import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker ,{ registerLocale }from "react-datepicker";
import Moment from "react-moment";

import lang from "date-fns/locale/zh-TW";

registerLocale("zh-TW", lang);



const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <div>
        <DatePicker
          className={getFieldCSSClasses(touched[field.name], errors[field.name])}
          style={{ width: "100%" }}
          dateFormat="yyyy年MM月dd日 aa hh:mm "
          showTimeSelect
          timeIntervals={5}
          timeFormat="HH:mm"
          selected={(field.value && new Date(field.value)) || null}
          autoComplete="off"
          locale="zh-TW"
          {...props}
          onChange={val => {
            setFieldValue(field.name, val);
          }}
        />
      </div>
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="feedback">
          例："2020年10月16日 下午 02:45 "
        </div>
      )}
    </>
  );
}
