import { createSlice } from "@reduxjs/toolkit";

const initialShoppingCartState = {
  actionsLoading: false,
  shoppingCarts: [],
  shoppingCartsTemp:[],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: initialShoppingCartState,
  reducers: {
    pushShoppingCarts: (state, action) => {
      state.actionsLoading = false;
      state.shoppingCarts.push(action.payload.shoppingCart);
    },
    deleteShoppingCarts: (state, action) => {
      state.shoppingCarts = state.shoppingCarts.filter(
        (el) => !action.payload.ids.includes(el)
      );
    },
    pushShoppingCartsTemp: (state, action) => {
      state.actionsLoading = false;
      state.shoppingCartsTemp.push(action.payload.shoppingCartsTemp);
    },
  },
});
