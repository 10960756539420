import axios from "axios";
import store from "../../../../../redux/store";

export const ip = process.env.REACT_APP_BACK_END;
// export const ip = "/api"

export const GET_PRODUCT_BY_ID = ip + "/product";
export const GET_SHOPPING_CART_BY_ID = ip + "/shoppingCart";
export const GET_MEMBER_BY_ID = ip + "/member";
export const CREATE_SHOPPING_CART = ip + "/shoppingCart";
export const CREATE_ORDER = ip + "/order";
export const CREATE_ECPAY = ip + "/ecpay";
export const GHOST_REGISTER_URL = ip + "/member/ghost";

const token = store.getState().auth.authToken;

export function getProductById(id) {
  const headers = {
    Authorization: token,
  };
  return axios.get(GET_PRODUCT_BY_ID + "/" + id, {
    headers: headers,
  });
}

export function getShoppingCartById(id) {
  const headers = {
    Authorization: token,
  };
  return axios.get(GET_SHOPPING_CART_BY_ID + "/" + id, {
    headers: headers,
  });
}

export function getUserById(id) {
  const headers = {
    Authorization: token,
  };
  return axios.get(GET_MEMBER_BY_ID + "/" + id, {
    headers: headers,
  });
}

export function createShoppingCart(request, authToken) {
  const headers = {
    Authorization: authToken ? authToken : token,
  };
  return axios.post(CREATE_SHOPPING_CART, request, {
    headers: headers,
  });
}

export function createOrder(request) {
  const headers = {
    Authorization: token,
  };
  return axios.post(CREATE_ORDER, request, {
    headers: headers,
  });
}

export function createGhostOrder(request, authToken) {
  const headers = {
    Authorization: authToken ? authToken : token,
  };
  return axios.post(CREATE_ORDER, request, {
    headers: headers,
  });
}

export function createPayment(ids) {
  return axios.post(CREATE_ECPAY, { orderIds: ids });
}

export function ghostRegister(request) {
  return axios.post(GHOST_REGISTER_URL, request);
}
