import React from "react";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(",")
    },

    palette: {
      primary: {
        light: "#4791db",
        main: "#1976d2",
        dark: "#115293",
        contrastText: "#fff", //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#e33371",
        main: "#dc004e",
        dark: "#9a0036",
        contrastText: "#000" 
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff", 
      },
      warring:{
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "#fff", 
      },
      info:{
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1976d2",
        contrastText: "#fff",
      },
      success:{
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: "#fff", 
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
