import axios from "axios";
import store from "../../../../../redux/store";

export const ip = process.env.REACT_APP_BACK_END;
// export const ip = "/api"
export const GET_PRODUCT_BY_ID = ip + "/product";
export const GET_PRODUCT_BY_URL = ip + "/product/url";
export const GET_SHOPPING_CART_BY_ID = ip + "/shoppingCart";
export const GET_MEMBER_BY_ID = ip + "/member";

const token = store.getState().auth.authToken;

export function getProductByUrl(url) {
  const headers = {
    Authorization: token,
  };
  return axios.get(GET_PRODUCT_BY_URL + "/" + url, {
    headers: headers,
  });
}

export function getUserById(id) {
  const headers = {
    Authorization: token,
  };
  return axios.get(GET_MEMBER_BY_ID + "/" + id, {
    headers: headers,
  });
}

export function getShoppingCartById(id) {
  const headers = {
    Authorization: token,
  };
  return axios.get(GET_SHOPPING_CART_BY_ID + "/" + id, {
    headers: headers,
  });
}
