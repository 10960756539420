/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import {LoginPage} from "./Login/LoginPage";
import {RegistrationPage} from "./Registration/RegistrationPage";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-9.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <h1
                className="mb-5 text-white"
                style={{
                  fontSize: "40px",
                  height: "20%",
                }}
              >
                <a
                  href="https://quicktrade-image.aiv.com.tw/"
                  style={{
                    color: "#0080FF",
                  }}
                >
                  <b>快交益</b>
                </a>
              </h1>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-top">
                <h3 className="font-size-h1 mb-5 "style={{
                  color:"#0080FF",
                }}>
                  <b>快交益！讓你交易就是快！</b>
                </h3>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-black">
                  &copy; 2020 智慧價值股份有限公司 All Rights Reserved.
                </div>
                {/* <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    隱私
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    <a className="text-white ml-10" href="">法律</a>
                    <a className="text-white ml-10" href="https://quicktrade-image.aiv.com.tw/Contact-Customer-Service.html">聯繫</a>
                </div> */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">沒有帳號嗎?</span>
              <Link
                to="/backend/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                立即註冊!
              </Link>
            </div>
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/backend/auth/login" component={LoginPage} />
                <ContentRoute
                  path="/backend/auth/registration"
                  component={RegistrationPage}
                />
                <ContentRoute
                  path="/backend/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/backend/auth/change-password"
                  component={ChangePassword}
                />
                <Redirect from="/backend/auth" exact={true} to="/backend/auth/login" />
                <Redirect to="/backend/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2020 智慧價值股份有限公司 All Rights Reserved.
              </div>
              {/* <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  隱私
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  法律
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  聯繫
                </Link>
              </div> */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
