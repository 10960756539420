import * as requestFromServer from "./ProductsCrud";
import { customerProductSlice, callTypes } from "./ProductsSlice";

const { actions } = customerProductSlice;

export const getProductByUrl = (url) => (dispatch) => {
  return requestFromServer.getProductByUrl(url).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.setProduct({ product: res.data }));
        break;
      default:
        break;
    }
  });
};

export const getSellerById = (id) => (dispatch) => {
  return requestFromServer.getUserById(id).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.setSeller({ seller: res.data }));
        break;
      default:
        break;
    }
  });
};

export const getSalesById = (id) => (dispatch) => {
  return requestFromServer.getUserById(id).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.setSales({ sales: res.data }));
        break;
      default:
        break;
    }
  });
};

export const getUserById = (id) => (dispatch) => {
  return requestFromServer.getUserById(id).then((response) => {
    const res = response.data;
    switch (res.code) {
      case "00000":
        dispatch(actions.setUserForShow({ userForShow: res.data }));
        break;
      default:
        break;
    }
  });
};
