import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export function MaterialCheckBox({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  url,
  ...props
}) {
  return (
    <>
      {url ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                value={field.name}
                color="primary"
                checked={field.value}
              />
            }
            label={
              <a href={url} target="_blank">
                {label ? label : url}
              </a>
            }
            {...field}
            {...props}
          />
        </>
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              value={field.name}
              color="primary"
              checked={field.value}
            />
          }
          label={label}
          {...field}
          {...props}
        />
      )}
    </>
  );
}
