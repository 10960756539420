import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ProductsPage } from "./products/ProductsPage";
import { ProductViewPage } from "./products/productView/ProductViewPage";
import { CheckoutPage } from "./checkout/CheckoutPage";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";

export function CustomerPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact={true} from="/customer" to="/customer/products" />}
        <ContentRoute path="/customer/products" component={ProductsPage} />

        <ContentRoute
          path="/customer/product/:url/:salesId"
          component={ProductViewPage}
        />
        <ContentRoute
          path="/customer/product/:url"
          component={ProductViewPage}
        />

        <ContentRoute path="/customer/checkout" component={CheckoutPage} />
      </Switch>
    </Suspense>
  );
}
