import axios from "axios";
import store from "../../../../../redux/store";

export const ip = process.env.REACT_APP_BACK_END;
// export const ip = "/api"

export const CREATE_SHOPPING_CART = ip + "/shoppingCart";

const token = store.getState().auth.authToken;

export function createShoppingCart(request) {
  const headers = {
    Authorization: token,
  };
  return axios.post(CREATE_SHOPPING_CART, request, {
    headers: headers,
  });
}
