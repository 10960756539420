import { createSlice } from "@reduxjs/toolkit";

const initialShippingMethodsState = {
  listLoading: false,
  actionsLoading: false,
  shippingMethods: [],
  shippingMethodForEdit: undefined,
};


export const ShippingMethodsSlice = createSlice({
  name: "shippingMethods",
  initialState: initialShippingMethodsState,
  reducers: {
    setShippingMethods: (state, action) => {
      state.actionsLoading = false;
      state.shippingMethods = action.payload.shippingMethods;
    },

    setShippingMethodForEdit: (state, action) => {
      state.actionsLoading = false;
      state.shippingMethodForEdit = action.payload.shippingMethod;
    },

    pushShippingMethods: (state, action) => {
      state.actionsLoading = false;
      state.shippingMethods.push(action.payload.shippingMethod);
    },

    putShippingMethods: (state, action) => {
      state.actionsLoading = false;
      state.shippingMethods = state.shippingMethods.map((shippingMethod) => {
        if (shippingMethod.id === action.payload.shippingMethod.id) {
          return action.payload.shippingMethod;
        }
        return shippingMethod;
      });
    },

    deleteShippingMethod: (state, action) => {
      state.actionsLoading = false;
      state.shippingMethods = state.shippingMethods.filter(
        (el) => el.id !== action.payload.id
      );
    },

    
  },
});
