import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import { Typeahead } from 'react-bootstrap-typeahead';

const isInvalid = (touched, errors) => {
  if (touched && errors) {
    return true
  }

  if (touched && !errors) {
    return false
  }

  return false
};

const isValid = (touched, errors) => {
    if (touched && errors) {
      return false
    }
  
    if (touched && !errors) {
      return true
    }
  
    return false
  };


export function Autocomplete({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <Typeahead

        isInvalid={isInvalid(touched[field.name], errors[field.name])}
        isValid={isValid(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
