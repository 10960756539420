import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../app/modules/Auth/_redux/AuthAction";
import Container from "@material-ui/core/Container";
import { Header } from "./components/header/Header";
import { LoginDialog } from "./components/dialog/Login/LoginDialog";

export function Layout({ children }) {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const logoutClick = () => {
    dispatch(actions.logout());
  };

  const loginClick = () => {
    setOpenLogin(true);
  };

  const registrationClick = () => {
    goToRegistration();
  };

  const backendClick = () => {
    goToBackend();
  };

  const goToRegistration = () => {
    history.push("/backend/auth/registration");
  };

  const goToBackend = () => {
    history.push("/backend");
  };

  const onHide = () => {
    setOpenLogin(false);
    setOpenRegistration(false);
  };

  return (
    <>
      <Header
        logoutClick={logoutClick}
        loginClick={loginClick}
        registrationClick={registrationClick}
        backendClick={backendClick}
      />
      <Container component="main" maxWidth="lg">
        {children}
      </Container>
      <LoginDialog show={openLogin} onHide={onHide} />
    </>
  );
}
