import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  getUserByToken: "[getUserByToken] Action",
  updateUser: "[updateUser] Action",
};

const initialAuthState = {
  member: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "quickTrade-auth", whitelist: ["member", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return {
          authToken: action.payload.session.token,
        };
      }
      case actionTypes.Logout: {
        return {
          authToken: undefined,
          member: undefined,
        };
      }
      case actionTypes.getUserByToken: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.updateUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (session) => ({
    type: actionTypes.Login,
    payload: session,
  }),
  logout: () => ({
    type: actionTypes.Logout,
  }),
  getUserByToken: (user) => ({
    type: actionTypes.getUserByToken,
    payload: user,
  }),
  updateUser: (user) => ({
    type: actionTypes.updateUser,
    payload: user,
  }),
};
