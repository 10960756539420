import { createSlice } from "@reduxjs/toolkit";

const initialCustomerCheckoutState = {
  actionsLoading: false,
  checkoutShoppingCartIds: [],
  checkoutShoppingCarts: [],
  checkoutItems: [],
  checkoutItemTemp: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const customerCheckoutSlice = createSlice({
  name: "customerCheckout",
  initialState: initialCustomerCheckoutState,
  reducers: {
    startCall: (state, action) => {
      state.actionsLoading = true;
    },
    setCheckoutShoppingCartIds: (state, action) => {
      state.actionsLoading = false;
      state.checkoutShoppingCartIds = action.payload.checkoutShoppingCartIds;
    },
    setCheckoutItems: (state, action) => {
      state.actionsLoading = false;
      state.checkoutItems = action.payload.checkoutItems;
    },
    setCheckoutItemTemp: (state, action) => {
      state.actionsLoading = false;
      state.checkoutItemTemp = action.payload.checkoutItemTemp;
    },

    pushCheckoutShoppingCarts: (state, action) => {
      state.actionsLoading = false;
      state.checkoutShoppingCarts.push(action.payload.checkoutShoppingCart);
    },
    pushCheckoutItems: (state, action) => {
      state.actionsLoading = false;
      state.checkoutItems.push(action.payload.checkoutItem);
    },

    clearCheckoutShoppingCartIds: (state, action) => {
      state.actionsLoading = false;
      state.checkoutShoppingCartIds = [];
    },
    clearCheckoutShoppingCarts: (state, action) => {
      state.actionsLoading = false;
      state.checkoutShoppingCarts = [];
    },
    clearCheckoutItems: (state, action) => {
      state.actionsLoading = false;
      state.checkoutItems = [];
    },
    clearCheckoutItemTemp: (state, action) => {
      state.actionsLoading = false;
      state.checkoutItemTemp = undefined;
    },
  },
});
