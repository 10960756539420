import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/AuthRedux";
import { customersSlice } from "../app/modules/Seller/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/Seller/_redux/products/ProductsSlice";
import { OrderSlice } from "../app/modules/Seller/_redux/order/OrdersSlice";
import { accountSlice } from "../app/modules/Basic/_redux/account/AccountOptionSlice";
import { walletSlice } from "../app/modules/Basic/_redux/wallet/WalletSlice";
import { walletLogsSlice } from "../app/modules/Basic/_redux/walletLogs/WalletLogsSlice";
import { withdrawalSlice } from "../app/modules/Manage/_redux/withdrawal/WithdrawalSlice";
import { productManageSlice } from "../app/modules/Manage/_redux/product/ProductManageSlice";
import { ShippingMethodsSlice } from "../app/modules/Seller/_redux/shippingMethods/ShippingMethodsSlice.js";
import { customerProductSlice } from "../app/modules/Customer/_redux/Products/ProductsSlice";
import { customerCheckoutSlice } from "../app/modules/Customer/_redux/Checkout/CheckoutSlice";
import { shoppingCartSlice } from "../app/modules/Customer/_redux/ShoppingCart/ShoppingCartSlice";
import { HistoryOrdersSlice } from "../app/modules/Buyer/_redux/HistoryOrders/HistoryOrdersSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  orders: OrderSlice.reducer,
  account: accountSlice.reducer,
  wallet: walletSlice.reducer,
  walletLogs: walletLogsSlice.reducer,
  withdrawal: withdrawalSlice.reducer,
  productManage: productManageSlice.reducer,
  shippingMethods: ShippingMethodsSlice.reducer,
  customerProduct: customerProductSlice.reducer,
  customerCheckout: customerCheckoutSlice.reducer,
  shoppingCart: shoppingCartSlice.reducer,
  historyOrders: HistoryOrdersSlice.reducer,
});

export function* rootSaga() {}
