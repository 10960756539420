import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  products: [],
  productForEdit: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const productManageSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {
    setProducts: (state, action) => {
      state.actionsLoading = false;
      state.products = action.payload.products;
    },


    pushProducts: (state, action) => {
      state.actionsLoading = false;
      state.products.push(action.payload.product);
    },

    putProducts: (state, action) => {
      state.actionsLoading = false;
      state.products = state.products.map((product) => {
        if (product.id === action.payload.product.id) {
          return action.payload.product;
        }
        return product;
      });
    },

    offshelfProduct: (state, action) => {
      state.actionsLoading = false;
      state.products = state.products.map((product) => {
        if (product.id === action.payload.product.id) {
          return action.payload.product;
        }
        return product;
      });
    },


  },
});
